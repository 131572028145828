<template>
	<div class="index">
		<div class="one_box">
			<div class="index_top">
				<!-- 工作，伙伴，网络。 -->{{$t('index.title1')}}<br>
				<!-- 需要自由职业者的一切 -->{{$t('index.title2')}}
			</div>
			<div class="index_msg">
				<!-- YOURS是为了让自由职业者更加闪耀而诞生的
				是新形式的SNS平台。 -->
				{{$t('index.slon1')}}
			</div>
			<div class="index_menu">
				<div class="menu">
					<img src="../assets/img/index_menu.png" class="menu_img" alt="">
					<div class="menu_name">
						<!-- 自由职业者・找到副业案件 -->{{$t('index.p1tit')}}
					</div>
					<div class="menu_name">
						<!-- 处理X职种的自由职业者·副业案件，不仅仅是企业案件，合作代理X公司的案件也全部一览化。此外，还可以统一管理招聘/星探到收款的案件流程。 -->{{$t('index.p1txt')}}
					</div>
				</div>
				<div class="menu">
					<img src="../assets/img/index_menu2.png" class="menu_img" alt="">
					<div class="menu_name">
						<!-- 自由职业者的实绩・信用可视化 -->{{$t('index.p2tit')}}
					</div>
					<div class="menu_name">
						<!-- 将自由职业者的经历、案件实绩、第三方评价、制作物等的简介全部集中在YOURS仪表板上。使作为自由职业者的职业积累成为可能，实现社会信用的可视化。 -->{{$t('index.p2txt')}}
					</div>
				</div>
				<div class="menu">
					<img src="../assets/img/index_menu3.png" class="menu_img" alt="">
					<div class="menu_name">
						<!-- 通过SNS与朋友联系 -->{{$t('index.p3tit')}}
					</div>
					<div class="menu_name">
						<!-- YOURS提供了超越作业匹配服务领域的自由职业者·副业工作者特化的SNS平台。自由职业者之间连接，可以进行开放的信息交换和社区形成。 -->{{$t('index.p3txt')}}
					</div>
				</div>
			</div>
			
			
			<!-- <img :src='"../assets/img/"+$t("index.index_one")' class="one_box" alt=""> -->
			<img src="../assets/img/index_one.png" v-if="$t('index.index_one')==1"  class="one_box" alt="">
			<img src="../assets/img/index_one_jp.png" v-else  class="one_box" style="width: 1203px;" alt="">
			<div class="xian">
				
			</div>
			<div class="one_title">
				<!-- 与现有作业匹配站点的不同 -->{{$t('index.title3')}}
			</div>
			<!-- <img src="../../src/assets/img/index_one_img.png" class="index_one_img" alt=""> -->
			<img src="../assets/img/index_one_img.png" v-if="$t('index.index_one_img')==1"  class="index_one_img" alt="">
			<img src="../assets/img/index_one_img_jp.png" v-else   class="index_one_img" alt="">
			<div class="one_btn_box" v-if="showlg">
				<router-link to="/register"><el-button type="primary" class="login_btn"><!-- 立即注册会员 -->{{$t('index.ljzchy')}}</el-button></router-link>
				<router-link to="/register?type=1"><el-button type="primary" class="QY_btn"><!-- 企业这边 -->{{$t('index.qyzb')}}</el-button></router-link>
			</div>
		</div>
		<div class="two_box">
			<div class="index_top" style="color: #fff;">
				<!-- 自由职业者、企业和公司职员们 -->{{$t('index.title4')}}<br>
				<!-- 今后，请采取更自由的工作方式。 -->{{$t('index.title5')}}
			</div>
			<div class="index_msg" style="color: #fff;">
				<!-- YOURS寻求更高质量的工作 -->{{$t('index.slon2')}}<br>
				<!-- 是面向所有人开发的服务。 -->{{$t('index.slon3')}}<br>
				<!-- 以下的各位，请一定要研究。 -->{{$t('index.slon4')}}
			</div>
			<img src="../../src/assets/img/index_two_img.png" class="index_two_img" alt="">
			<div class="two_list">
				<div class="list_box">
					<div class="list_title">
						<img src="../../src/assets/img/two_G.png" class="G" alt="">
						<!-- 自由职业者・副业工作者们 -->{{$t('index.ystit1')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 想建立自由职业者、副业工作者之间的联系 -->{{$t('index.ystxt1')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 想扩大工作范围，积累经验 -->{{$t('index.ystxt2')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 想要实际案件的凭证 -->{{$t('index.ystxt3')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 我想找个人做一部分工作 -->{{$t('index.ystxt4')}}
					</div>
				</div>
				<div class="list_box">
					<div class="list_title">
						<img src="../../src/assets/img/two_G.png" class="G" alt="">
						<!-- 公司职员们 -->{{$t('index.ystit2')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 想轻松地知道有什么副业案件 -->{{$t('index.ystxt5')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 希望有关于副业的信息交换场所 -->{{$t('index.ystxt6')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 对不受公司束缚的生活感兴趣 -->{{$t('index.ystxt7')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 想为创业准备资金 -->{{$t('index.ystxt8')}}
					</div>
				</div>
			</div>
			<div class="two_list">
				<div class="list_box">
					<div class="list_title">
						<img src="../../src/assets/img/two_G.png" class="G" alt="">
						<!-- 企业的各位 -->{{$t('index.ystit3')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 希望灵活地确保专业性高的人才 -->{{$t('index.ystxt9')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 通过咨询公司的话，费用感不合适 -->{{$t('index.ystxt10')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 指名购入优秀人材 -->{{$t('index.ystxt11')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 想防止录用的不匹配 -->{{$t('index.ystxt12')}}
					</div>
				</div>
				<div class="list_box">
					<div class="list_title">
						<img src="../../src/assets/img/two_G.png" class="G" alt="">
						<!-- 创业者·经营者们 -->{{$t('index.ystit4')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 想在本职工作的同时做自己喜欢的工作 -->{{$t('index.ystxt13')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 想扩大人脉和网络 -->{{$t('index.ystxt14')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 想委托自由职业者、副业工作者工作 -->{{$t('index.ystxt15')}}
					</div>
					<div class="list">
						<img src="../../src/assets/img/two_yes.png" class="yes" alt="">
						<!-- 想宣传自己公司的事业 -->{{$t('index.ystxt16')}}
					</div>
				</div>
			</div>
			<div class="two_xian">
				
			</div>
			<div class="index_top" style="color: #fff;">
				<!-- 招聘信息 -->{{$t('index.zpxx')}}
			</div>
			<div class="job_list" @click="job_page(item.job_id)" style="margin-top: 15px;cursor: pointer;" v-for="item in workList" :key="item.job_id">
				<img :src="item.avatar_url" class="list_img" alt="">
				<div class="list_center">
					<div class="center_title">
						{{item.job_name}}
					</div>
					<div class="center_list">
						{{item.job_desc}}
					</div>
					<div class="center_text">
						<!-- {{item.salary_low}} - {{item.salary_high}} {{item.salary_unit}} -->
						{{$t('BYJN')}}：{{item.skills.join(' , ')}}
					</div>
				</div>
				<div class="list_right">
					<div class="right_box">
						<div class="box_title">
							<!-- 位置 --><!-- {{$t('index.weizhi')}} -->
							{{$t('other.XYRS')}}
						</div>
						<div class="box_msg">
							{{item.job_num_people}} {{$t('REN')}}
							<!-- <div class="msg">
								（可在家）
							</div> -->
						</div>
					</div>
					<div class="right_box">
						<div class="box_title">
							<!-- 薪资 -->{{$t('index.xinzi')}}
						</div>
						<div class="box_msg">
							{{item.salary_low}} - {{item.salary_high}} {{item.salary_unit}}
						</div>
					</div>
				</div>
			</div>
			<router-link to="/look_job">
				<el-button type="primary" class="two_last"><!-- 查看更多 -->{{$t('CKGD')}}</el-button>
			</router-link>
		</div>
		
		<div class="three_box">
			<div class="index_top">
				<!-- 我有一份很适合你的工作 -->{{$t('index.title6')}}
			</div>
			<div class="index_msg">
				<!-- 正在注册各种职业的云工作者。 -->{{$t('index.slon5')}}<br>
				<!-- 与工作的规模、速度感、预算相匹配 -->{{$t('index.slon6')}}<br>
				<!-- 你可以找优秀的人才，组队。 -->{{$t('index.slon7')}}
			</div>
			<div class="people_list">
				<div class="list_box" style="cursor: pointer;" @click="touser(item.user.user_id)" v-for="item in peopleList" :key="item.resume_id">
					<img :src="item.user.avatar_url" class="list_img" alt="">
					<div class="list_name">
						{{item.user.nickname}}
					</div>
					<div class="list_msg">
						{{item.user.profession}}
					</div>
				</div>
				
			</div>
			<div class="xian">
				
			</div>
			<div class="type_list">
				<div class="list_box">
					<div class="box_title">
						<!-- 咨询 -->{{$t('index.zixun')}}
					</div>
					<div class="box_name">
						<!-- 咨询战略咨询 -->{{$t('index.zixun1')}}
					</div>
					<div class="box_name">
						<!-- 财务和会计咨询 -->{{$t('index.zixun2')}}
					</div>
					<div class="box_name">
						<!-- 人事和组织咨询 -->{{$t('index.zixun3')}}
					</div>
					<div class="box_name">
						<!-- 业务流程咨询 -->{{$t('index.zixun4')}}
					</div>
					<div class="box_name">
						<!-- 物流、SCM咨询 -->{{$t('index.zixun5')}}
					</div>
					<div class="box_name">
						<!-- 营销咨询 -->{{$t('index.zixun6')}}
					</div>
					<div class="box_name">
						<!-- 研究，研究 -->{{$t('index.zixun7')}}
					</div>
					<div class="box_name">
						<!-- IT咨询 -->{{$t('index.zixun8')}}
					</div>
				</div>
				<div class="list_box">
					<div class="box_title">
						<!-- 商业 -->{{$t('index.shangye')}}
					</div>
					<div class="box_name">
						<!-- 经营企划、经营战略立案 -->{{$t('index.shangye1')}}
					</div>
					<div class="box_name">
						<!-- 新事业企划、事业开发 -->{{$t('index.shangye2')}}
					</div>
					<div class="box_name">
						<!-- 并购、合作 -->{{$t('index.shangye3')}}
					</div>
					<div class="box_name">
						<!-- 财务、会计、会计 -->{{$t('index.shangye4')}}
					</div>
					<div class="box_name">
						<!-- 内部审核和内部控制 -->{{$t('index.shangye5')}}
					</div>
					<div class="box_name">
						<!-- 销售 -->{{$t('index.shangye6')}}
					</div>
				</div>
				<div class="list_box">
					<div class="box_title">
						<!-- 人事 -->{{$t('index.renshi')}}
					</div>
					<div class="box_name">
						<!-- 聘用 -->{{$t('index.renshi1')}}
					</div>
					<div class="box_name">
						<!-- 组织开发和制度设计 -->{{$t('index.renshi2')}}
					</div>
					<div class="box_name">
						<!-- 劳务、工资 -->{{$t('index.renshi3')}}
					</div>
					
				</div>
				<div class="list_box">
					<div class="box_title">
						<!-- IT・开发 -->{{$t('index.it')}}
					</div>
					<div class="box_name">
						<!-- Web应用程序开发 -->{{$t('index.it1')}}
					</div>
					<div class="box_name">
						<!-- 移动应用程序开发 -->{{$t('index.it2')}}
					</div>
					<div class="box_name">
						<!-- 基础设施设计和构建 -->{{$t('index.it3')}}
					</div>
					<div class="box_name">
						<!-- 安全措施 -->{{$t('index.it4')}}
					</div>
					<div class="box_name">
						<!-- 系统运行维护 -->{{$t('index.it5')}}
					</div>
					<div class="box_name">
						<!-- ERP导入、CRM导入 -->{{$t('index.it6')}}
					</div>
					<div class="box_name">
						<!-- 数据分析和数据利用 -->{{$t('index.it7')}}
					</div>
					<div class="box_name">
						<!-- 网络主管 -->{{$t('index.it8')}}
					</div>
					<div class="box_name">
						<!-- PMO -->{{$t('index.it9')}}
					</div>
				</div>
				
				
				<div class="list_box">
					<div class="box_title">
						<!-- 设计 -->{{$t('index.sheji')}}
					</div>
					<div class="box_name">
						<!-- 图形设计 -->{{$t('index.sheji1')}}
					</div>
					<div class="box_name">
						<!-- WebUI设计 -->{{$t('index.sheji2')}}
					</div>
					<div class="box_name">
						<!-- WebUX设计 -->{{$t('index.sheji3')}}
					</div>
					<div class="box_name">
						<!-- 产品设计 -->{{$t('index.sheji4')}}
					</div>
					<div class="box_name">
						<!-- 包装设计 -->{{$t('index.sheji5')}}
					</div>
					<div class="box_name">
						<!-- 艺术指导 -->{{$t('index.sheji6')}}
					</div>
					<div class="box_name">
						<!-- 创意指导 -->{{$t('index.sheji7')}}
					</div>
					<div class="box_name">
						<!-- 空间・店铺设计 -->{{$t('index.sheji8')}}
					</div>
					<div class="box_name">
						<!-- 室内设计 -->{{$t('index.sheji9')}}
					</div>
				</div>
				<div class="list_box">
					<div class="box_title">
						<!-- 市场营销 -->{{$t('index.scyx')}}
					</div>
					<div class="box_name">
						<!-- 消隐 -->{{$t('index.scyx1')}}
					</div>
					<div class="box_name">
						<!-- 广告宣传 -->{{$t('index.scyx2')}}
					</div>
					<div class="box_name">
						<!-- 商品企划・开发 -->{{$t('index.scyx3')}}
					</div>
					<div class="box_name">
						<!-- 网络广告运营 -->{{$t('index.scyx4')}}
					</div>
					<div class="box_name">
						<!-- 数字营销 -->{{$t('index.scyx5')}}
					</div>
					
				</div>
				<div class="list_box">
					<div class="box_title">
						<!-- 创意制作 -->{{$t('index.cyzz')}}
					</div>
					<div class="box_name">
						<!-- 动画、影像制作、编辑 -->{{$t('index.cyzz1')}}
					</div>
					<div class="box_name">
						<!-- 摄影、编辑 -->{{$t('index.cyzz2')}}
					</div>
					<div class="box_name">
						<!-- 创建主页 -->{{$t('index.cyzz3')}}
					</div>
					<div class="box_name">
						<!-- 照明 -->{{$t('index.cyzz4')}}
					</div>
					<div class="box_name">
						<!-- 复印照明 -->{{$t('index.cyzz5')}}
					</div>
					<div class="box_name">
						<!-- 插图开发 -->{{$t('index.cyzz6')}}
					</div>
					<div class="box_name">
						<!-- 乐曲制作 -->{{$t('index.cyzz7')}}
					</div>
				</div>
				<div class="list_box">
					<div class="box_title">
						<!-- 其他 -->{{$t('index.qita')}}
					</div>
					<div class="box_name">
						<!-- 翻译、口译 -->{{$t('index.qita1')}}
					</div>
					<div class="box_name">
						<!-- 摄影模型 -->{{$t('index.qita2')}}
					</div>
					<div class="box_name">
						<!-- 数据输入 -->{{$t('index.qita3')}}
					</div>
					<div class="box_name">
						<!-- 各种代理 -->{{$t('index.qita4')}}
					</div>
					
				</div>
				
			</div>
			<div class="one_btn_box"  v-if="showlg">
				<router-link to="/register"><el-button type="primary" class="login_btn"><!-- 立即注册会员 -->{{$t('index.ljzchy')}}</el-button></router-link>
				<router-link to="/register?type=1"><el-button type="primary" class="QY_btn"><!-- 企业这边 -->{{$t('index.qyzb')}}</el-button></router-link>
			</div>
		</div>
		
		<div class="four_box">
			<div class="index_top" style="color: #fff;">
				<!-- 将技能、经历、优势可视化 -->{{$t('index.title7')}}
				<!-- YOURS仪表盘 -->{{$t('index.title8')}}
			</div>
			<div class="index_msg" style="color: #fff;">
				<!-- 以工作场所、时间、希望的价格带为首 -->{{$t('index.slon8')}}<br>
				<!-- 在仪表板上可视化技能和业绩等。 -->{{$t('index.slon9')}}<br>
				<!-- 实现最适合的工作的匹配。 -->{{$t('index.slon10')}}
				
			</div>
			<img src="../../src/assets/img/index_four.png"  alt="">
		</div>
		<div class="five_box">
			<div class="index_top">
				<!-- 发出自己，与世界相连。 -->{{$t('index.title9')}}
			</div>
			<div class="index_msg">
				<!-- 工作的募集和应募自不必说，就像SNS一样 -->{{$t('index.slon10')}}<br>
				<!-- 一边自由地表现你的个性 -->{{$t('index.slon11')}}<br>
				<!-- 我们可以沟通。 -->{{$t('index.slon12')}}
			</div>
			<img src="../../src/assets/img/index_five.png" alt="">
		</div>
		<div class="six_box">
			<div class="box_left">
				<!-- 自己的工作方式由自己决定。 -->{{$t('index.title10')}}<br>
				<!-- YOURS通过工作闪耀 -->{{$t('index.title11')}}<br>
				<!-- 和朋友相遇 -->{{$t('index.title12')}}<br>
				<!-- 为了想享受人生的人 -->{{$t('index.title13')}}<br>
				<!-- 是新的“栖息地”。 -->{{$t('index.title14')}}
			</div>
			<div class="box_right">
				<div class="right_title">
					I prefer dangerous freedom over peaceful slavery
				</div>
				<div class="right_msg">
					（<!-- 以自由的生活方式、工作方式为志向，自己掌握人生的方向盘 -->{{$t('index.slon13')}}）
				</div>
			</div>
		</div>
		<!-- <div class="last_bottom">
			<img src="../../src/assets/img/bottom_logo.png" class="logo" alt="">
			<div class="bottom_text">
				ABOUT US
			</div>
			<div class="bottom_text">
				隐私政策
			</div>
			<div class="bottom_text">
				使用条款
			</div>
			<div class="bottom_text">
				处理个人信息
			</div>
			<div class="bottom_text">
				登录
			</div>
		</div> -->
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				navIndex: 0,
				
				workList:[],
				peopleList:[],
				showlg:1
			};
		},
		created() {
			this.getList();
			this.getPeople();
			if(this.$local.get('t_k')){
				this.showlg=0;
			}
		},
		watch: {
			
		},
		methods: {
			touser(id){
				this.$router.push('/people_page?user_id='+id);
			},
			async job_page(id){
				this.$router.push('/job_detail?job_id='+id);
			},
			//获取工作
			async getList(t){
				let data={
					currentPage:1,
					pageSize: 3,
					keyword: '',
					skill: '',					
					salary_low: '',
					salary_high: '',
					entrust_method:'',
					job_method:'',
					fast_method:'',
					job_nature: '',
					sortRules: {
						hot_score: '',
						job_id: '',
						salary_low: '',
						create_time: '',
						job_end_time: ''
					}
				};
				let res=await this.$request.post('/api/job/findPage',data);
				this.workList=res.data.records;
			},
			async getPeople(t){
				
				let data={
					currentPage: 1,
					keyword: '',				    
				    pageSize: 4,
				   working_status:'',
				    wage_low: '',
				    wage_high: '',
				    skill: '',
				    sortRules: {
				      score: 'DESC',
				      curr_login_time: 'DESC',
				      hope_wage: 'DESC',
				      user_id: 'ASC'
				    }
				};
				
				
				let res=await this.$request.post('/api/resume/findPage',data);
				
					this.peopleList=res.data.records;
				
				
			},
			
		},
		
	};
</script>

<style lang="less" scoped>
	.index{
		line-height: 1.2;
		min-height: 100%!important;
		background-color: #F3F1EA;
		padding-top: 60px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: center;
		.one_box{
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-bottom: 120px;
		}
		.index_top{
			//width: 500px;
			color: #567BB6;
			font-size: 30px;
			text-align: center;
			font-weight: bold;
			line-height: 1.2;
			margin-bottom: 20px;
		}
		.index_msg{
			width: 500px;
			margin-bottom: 40px;
			font-size: 20px;
			color: #567BB6;
			text-align: center;
			line-height: 1.2;
		}
		.index_menu{
			display: flex;
			justify-content: center;
			.menu{
				width: 315px;
				margin: 30px 50px;
				.menu_img{
					width: 315px;
					height: 168px;
					margin-bottom: 20px;
				}
				.menu_name{
					text-align: center;
					font-size: 15px;
					margin-bottom: 20px;
					color: #567BB6;
				}
			}
		}
		.one_img{
			width: 1030px;
			height: 824px;
		}
		.xian{
			width: 1326px;
			height: 2px;
			background-color: #567BB6;
			margin: 90px 0;
		}
		.one_title{
			font-size: 30px;
			text-align: center;
			color: #567BB6;
			font-weight: bold;
			margin: 0 80px;
		}
		.index_one_img{
			width: 1329px;
			
			margin: 80px 0;
		}
		.one_btn_box{
			display: flex;
			align-items: center;
			width: 800px;
			justify-content: space-between;
			.login_btn{
				width: 339px;
				height: 50px;
				background: #B65664;
				border-radius: 3px;
				border: none;
			}
			.QY_btn{
				width: 339px;
				height: 50px;
				background: #3897A3;
				border-radius: 3px;
				border: none;
			}
		}
		
		.two_box{
			width: 100%;
			background-color: #567BB6;
			padding: 80px 0;
			display: flex;
			align-items: center;
			flex-direction: column;
			.index_two_img{
				width: 779px;
				height: 466px;
			}
			.two_list{
				display: flex;
				justify-content: space-between;
				width: 1100px;
				margin: 48px 0;
				margin-bottom: 0;
				.list_box{
					.list_title{
						width: 427px;
						height: 60px;
						border-top: 1px solid #FFFFFF;
						border-bottom: 1px solid #FFFFFF;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 21px;
						color: #FFFFFF;
						font-weight: bold;
						.G{
							width: 27px;
							height: 21px;
							margin-right: 10px;
						}
					}
					.list{
						font-size: 15px;
						color: #fff;
						display: flex;
						align-items: center;
						margin:  30px 0;
						.yes{
							width: 21px;
							height: 21px;
							
							margin-right: 15px;
						}
					}
				}
			}
			.two_xian{
				width: 1331px;
				height: 1px;
				background-color: #FFFFFF;
				margin: 50px 0;
			}
			.job_list{
				width: 1329px;
				min-height: 178px;
				background: #FFFFFF;
				border-radius: 3px;
				padding: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				box-sizing: border-box;
				.list_img{
					width: 120px;
					height: 120px;
					background: #D8D8D8;
					border: 1px solid #979797;
				}
				.list_center{
					.center_title{
						width: 800px;
						font-weight: bold;
						font-size: 21px;
						color: #212121;
						margin-bottom: 15px;
					}
					.center_list{
						width: 800px;
						font-size: 15px;
						margin-bottom: 15px;
					}
					.center_text{
						width: 800px;
						font-size: 14px;
					}
				}
				.list_right{
					display: flex;
					align-items: center;
					.right_box{
						width: 129px;
						height: 129px;
						border-left: 1px solid #979797;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						padding: 15px;
						box-sizing: border-box;
						.box_title{
							font-size: 14px;
							margin-bottom: 20px;
						}
						.box_msg{
							font-size: 21px;
							text-align: center;
							font-weight: bold;
							.msg{
								font-size: 15px;
							}
						}
					}
					
				}
			}
			.two_last{
				width: 171px;
				height: 51px;
				border-radius: 3px;
				color: #fff;
				background-color: #567BB6;
				border: 2px solid #FFFFFF;
				margin-top: 60px;
				font-size: 21px;
			}
		
		}
		
		.three_box{
			padding: 85px 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			.people_list{
				display: flex;
				flex-wrap: wrap;
				width: 1101px;
				margin-top: 20px;
				.list_box{
					margin-right: 60px;
					width: 228px;
					flex-shrink: 0;
					margin-bottom: 45px;
					.list_img{
						width: 228px;
						height: 230px;
						margin-bottom: 20px;
						border-radius: 20px;
					}
					.list_name{
						font-size: 15px;
						color: #567BB6;
						margin-bottom: 15px;
					}
					.list_msg{
						font-size: 14px;
						color: #567BB6;
					}
				}
				.list_box:nth-child(4n){
					margin-right: 0;
				}
			}
			.xian{
				width: 1102px;
				height: 1px;
				background-color: #567BB6;
				margin: 30px 0;
			}
			.type_list{
				width: 954px;
				display: flex;
				justify-content: space-between;
				color: #567BB6;
				margin-bottom: 30px;
				flex-wrap: wrap;
				.list_box{
					width: 206px;
					margin-right: 40px;
					.box_title{
						font-size: 15px;
						margin-bottom: 20px;
					}
					.box_name{
						font-size: 12px;
						margin-bottom: 10px;
					}
				}
				.list_box:nth-child(4n){
					margin-right: 0;
				}
			}
			.one_btn_box{
				display: flex;
				align-items: center;
				width: 800px;
				justify-content: space-between;
				.login_btn{
					width: 339px;
					height: 50px;
					background: #B65664;
					border-radius: 3px;
					border: none;
				}
				.QY_btn{
					width: 339px;
					height: 50px;
					background: #3897A3;
					border-radius: 3px;
					border: none;
				}
			}
		}
		.four_box{
			width: 100%;
			background-color: #567BB6;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 85px 0;
			.four_img{
				width: 998px;
				height: 866px;
			}
		}
		.five_box{
			width: 100%;
			// background-color: #567BB6;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 85px 0;
			.four_img{
				width: 998px;
				height: 866px;
			}
		}
		.six_box{
			width: 100%;
			background-color: #567BB6;
			padding: 80px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			.box_left{
				width: 450px;
				font-size: 30px;
				color: #fff;
				margin-right: 80px;
			}
			.box_right{
				font-size: 30px;
				color: #fff;
				display: flex;
				flex-direction: column;
				align-items: center;
				.right_title{
					
					font-size: 40px;
					color: #fff;
					margin-bottom: 20px;
				}
				.right_msg{
					font-size: 21px;
				}
			}
		}
		.last_bottom{
			width: 100%;
			height: 76px;
			background: #212121;
			display: flex;
			align-items: center;
			justify-content: center;
			.logo{
				width: 82px;
				height: 22px;
				margin: 0 60px;
			}
			.bottom_text{
				color: #fff;
				font-size: 14px;
				margin: 0 60px;
			}
		}
	}
	
</style>
